<template>
  <div>
    <swiper
      :loop="true"
      :modules="modules"
      :slides-per-view="1"
      :space-between="0"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      v-for="(d,i) in holeData" :key="i"
    >
      <template v-if="i == show">
        <swiper-slide v-for="(v, k) in d" :key="k">
          <h2 style="font-style: italic">Hole <span style="font-size: 2em; color: black;">{{ v.hole }}</span></h2>
          <img :src="shotNavi + v.param" alt="">
          <p v-if="v.TeeShot" style="padding: 0 1em; text-align: left;">TeeShot: {{ v.TeeShot }}</p>
          <p v-if="v.TargetScore" style="padding: 0 1em; text-align: left;">TargetScore: {{ v.TargetScore }}</p>
          <p v-if="v.note" style="padding: 0 1em; text-align: left;">{{ v.note }}</p>
        </swiper-slide>
      </template>
    </swiper>
    <div id="navi">
      <div v-for="(v,k) in holeData" :key="k" v-text="k" @click="show = k" :class="`${k} ${k == show ? 'active': ''}`"></div>
    </div>
  </div>
</template>
<script>
  import { ref } from 'vue'
  // import Swiper core and required modules
  import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';

  import holeData from '../assets/yamatofudou.json'

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      let show = ref('OUT')
      let shotNavi = '//shotnavi.jp/gcguide/cdata/himg.php';
      const gcid = 695;
      shotNavi += `?gcid=${gcid}`;
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        show,
        shotNavi,
        holeData,
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y],
      };
    },
  };
</script>
<style>
p{
  box-sizing: border-box;
  white-space: pre-wrap;
}
#navi{
  display: flex;
}
#navi > *{
  width: 50%;
  padding: 1em;
  background: #0d7526;
  color: white;
  font-style: italic;
  font-weight: bold;
}
#navi > .active{
  background: #1d4c28;
}
</style>
